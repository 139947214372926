.last{
    background: #1E2947;
    min-height: 10rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    text-align: center;
}
.icon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10rem;
}
.input{
    background-color: rgba(255, 255, 255, .15);  
 backdrop-filter: blur(20px);
 border: none;
 color: #FFFFFF;
 padding: 0 0 0 1rem;
}
.input:focus {
    border: none;
}
.input::placeholder{
    text-align: center;
    color: bisque;
}

@media (max-width: 768px){
    .last{
        flex-direction: column;
        padding: 2rem 0 2rem 0;
    }
    .sh{
        padding: 1rem 0 1rem 0;
    }
}