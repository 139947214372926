@font-face {
  font-family: comicon;
  src: url('./Comicon.otf');
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Code Pro', monospace;
  
}